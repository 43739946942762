::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #bbb;
}
.contentLayout {
  display: flex;
}
.contentLayoutColumn {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.contentsArea {
  padding: 10px;
}
.pageHead {
  font-size: 20px;
  font-weight: 500;
}
.changeable {
  background-color: #fff;
  border-top: 3px solid green;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 0px 1px;
}

.addData {
  margin-top: 15px;
  padding-top: 15px;
}

.filterSec {
  display: flex;
  justify-content: space-between;
}

.addBtn {
  font-size: 15px !important;
  font-weight: 400;
  padding: 12px 20px !important;
}

.addBtnIcon {
  margin-right: 6px;
  font-size: 18px !important;
}

.headingDiv {
  margin-bottom: 15px;
}
.title {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.navItem {
  padding: 4px;
  border-radius: 0% !important;
  padding-left: 20px;
  padding-right: 20px;
}
.navItem:link,
.navItem:visited {
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

.BillHeader {
  display: flex;
}
.buttonWrapper {
  display: flex;
  padding-top: 25px !important;
}
.modeItems {
  display: flex;
  padding-left: 16px !important;
}
.BillHeaderDiv {
  margin-bottom: 15px;
}
.fieldTitleDiv {
  width: 35%;
  padding-left: 10px;
}
.fieldTitle {
  vertical-align: -webkit-baseline-middle;
  font-size: 14px;
  font-weight: 600;
  text-align: end;
  text-transform: capitalize;
}
.fieldTitleSmall {
  vertical-align: -webkit-baseline-middle;
  font-size: 14px;
  font-weight: 500;
  text-align: end;
  color: #7e7e7e;
}
.fieldContent {
  width: 65%;
}
.fieldHead {
  width: 30%;
}
.fieldInput {
  width: calc(50% - 30px);
}
.fieldInputFull {
  width: calc(70% - 15px);
}
.fieldButtons {
  width: 20%;
  margin-left: 15px;
}
.fieldButtonsEnd {
  width: 20%;
}

.Bill {
  width: 45%;
  border-radius: 5px;
  height: 100%;
  padding: 15px;
  background-color: #fff;
}

.buttonArea {
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
}
.billPreview {
  color: #666;
}
.billSample {
  margin: auto;
  width: 250px;
  width: clac(100%-30px);
  height: 100%;
  padding: 10px 13px 15px;
  text-transform: uppercase;
  border: 1px solid #444;
}
.borderBottomDotted {
  border-bottom: 1.5px dotted rgb(75, 71, 71);
}
.borderTOpDotted {
  border-top: 1.5px dotted rgb(75, 71, 71);
}
h3,
h2,
h5,
h6 {
  margin: 0% !important;
}
.settingsGrid {
  width: calc(100% - 20px);
  border-radius: 5px;
  height: 100%;
  padding: 10px;
  margin-top: 20px;
  background-color: #fff;
}
.subsTitle {
  font-size: 20px;
  font-weight: 500;
  padding: 0px 15px;
}
.marginTop {
  margin-top: 4px;
}

/* short key section */
.shorKeyContainer {
  padding: 15px;
  width: 100%;
}
.selectMachineSection {
  width: 30%;
}
.machineSelectTitle {
  font-size: 15px;
  font-weight: 500;
}
.machineTypes {
  padding: 20px 0;
}
.machineWrapper {
  min-width: 80px;
  max-width: 110px;
  padding: 15px;
  margin: 0 0 15px 20px;
  border: 1px solid #ddd;
  transition: border 0.3s, background 1s, color 0.5s;
  border-radius: 5px;
  background: transparent;
  text-align: center;
  cursor: pointer;
}
.machineWrapper:hover {
  border: 1px solid #aaa;
}
.selectedMachine {
  background: #fc9a02;
  color: white;
}
.machineImage {
  width: 100%;
}
.machineName {
  font-size: 13px;
  text-align: center;
}
.keyListContainer {
  display: flex;
  flex-wrap: wrap;
}
.keyItem {
  min-width: 35px;
  padding: 10px 15px;
  margin: 0 0 20px 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: center;
  display: flex;
  flex-direction: column;
  transition: border 0.3s;
  cursor: pointer;
}
.keyItemDisabled {
  padding: 10px;
  margin: 0 0 20px 20px;
  border: 1px solid #ddd;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  text-align: center;
  display: flex;
  flex-direction: column;
  transition: border 0.3s;
  filter: opacity(0.5);
}
.keyItem:hover {
  border: 1px solid #888;
}
.keyName {
  font-size: 20px;
}
.keyStatus {
  font-size: 13px;
  color: #fc9a02;
}
.popUp {
  height: calc(100vh - 100px);
  width: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
}
.checkbox {
  display: flex;
  justify-content: space-around;
  padding: 50px 0px;
}
/* Permission detail */
.userDetailDiv {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.userBox {
  min-width: 15%;
  margin-right: 10px;
}
.userCard {
  min-height: 25px;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: space-between;
}
.padding15 {
  padding: 15px;
}
.flexSpaceBw {
  display: flex;
  justify-content: space-between;
}

.actionBtnIcon {
  margin-right: 6px;
  font-size: 16px !important;
}

.actionBtn {
  font-size: 12px !important;
  font-weight: 400;
  margin-left: 5px !important;
}
.subHeader {
  margin: 10px 0 !important;
}
.subHeader span {
  font-size: 15px;
  font-weight: 700;
}
.button {
  margin: 10px !important;
  padding: 5px 40px !important;
  background-color: #00a65a !important;
}
.categoryImg {
  background-size: contain !important;
  background-position: left !important;
  width: 100%;
  height: 150px;
  margin-bottom: 20px;
}

.uploadBtn {
  background-color: #fc9a02 !important;
  color: white !important;
}
.imageSizeWarning {
  font-size: 14px;
  font-style: italic;
  padding-bottom: 10px;
  color: #f00;
}
.categoryImg {
  background-size: contain !important;
  background-position: left !important;
  width: 100%;
  height: 150px;
  margin-bottom: 20px;
}
.showDialog {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 999;
}
