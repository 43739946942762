.contentWrapper {
  padding: 10px;
  margin-left: 0 !important;
  height: 100%;
  display: flex;
  align-content: flex-start;
  overflow-y: hidden;
}

.title {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  flex: auto;
}

.menuTitle {
  font-size: 15px;
  font-weight: 400;
  padding-left: 8px;
  color: gray;
}

.titleSec {
  display: flex;
}

/* changeable section */

.changeable {
  background-color: #fff;
  border-top: 1px solid #d2d6de;
  margin-top: 10px !important;
  border-radius: 5px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 0px 1px;
  height: calc(100% - 42px);
  overflow-x: hidden;
  display: flex;
  align-content: flex-start;
}

.changeable::-webkit-scrollbar {
  height: 0;
}
.changeable::-webkit-scrollbar-thumb {
  width: 0;
}

.subTitle {
  font-size: 18px;
  font-weight: 500;
}

/* filter section */

.filterSec {
  display: flex;
  justify-content: space-between;
}

.filterDiv {
  margin-right: 15px;
}

.filerInputSec {
  display: flex;
}

/* search input section */

.searchSec {
  padding: 5px;
  margin-left: 20px;
  min-width: 250px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid rgba(0, 0, 1, 0.1);
}

.serachSec:focus-within {
  border-color: black;
}

.searchInput {
  border: none;
  width: 80%;
  font-size: 17px;
}

.searchInput::placeholder {
  font-size: 15px;
  color: rgba(0, 0, 1, 0.3);
}

.searchInput:focus-visible {
  outline: none;
}

.searchIcon {
  font-size: 28px;
}

/* action button section */

.actionButtons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 4px;
  /* margin: 20px 0 10px; */
}

.actionBtn {
  font-size: 12px !important;
  font-weight: 400;
}

.actionBtnIcon {
  margin-right: 6px;
  font-size: 16px !important;
}

.inputLabelContainer {
  display: flex;
}

.inputLabel {
  font-size: 14px;
  font-weight: 550;
  padding-right: 30px;
  text-align: end;
}

.label {
  font-size: 12px;
  font-weight: 500;
}

.inputRow {
  margin-bottom: 10px;
}

.columnCell {
  font-weight: 700 !important;
}

.nameColumn {
  font-weight: 700 !important;
  width: 20%;
}
.headKeyColumn {
  width: 80px;
  text-align: right !important;
  font-weight: 500 !important;
  padding-right: 70px !important;
}
.headValueColumn {
  width: 80px;
  text-align: center !important;
  font-weight: 500 !important;
}
.submitSection {
  padding-top: 15px;
  margin: 15px;
  margin-left: 0;
  display: flex;
  justify-content: flex-end;
}

.link {
  text-decoration: none;
}

.closeBtn {
  margin-right: 10px !important;
}

.marginTop {
  margin-top: 4px;
}

.normalText {
  margin: 0 8px;
}

.tableContainer {
  height: fit-content;
  width: 100%;
  display: flex;
  position: relative !important;
}

.subHeader {
  font-size: 18px;
  color: #0160c9;
  font-weight: 600;
}

.link {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.link span {
  text-decoration: none;
  font-size: 13px;
  font-weight: 500;
  color: #084c98;
  font-style: oblique;
  cursor: pointer;
}
.link a {
  text-decoration: none;
}

.activeContainer {
  height: calc(100%);
  position: relative;
}
.moreIcon {
  position: absolute;
  right: 10px;
  bottom: 40px;
  color: #95a6c4;
  cursor: pointer;
}
.moreIcon:hover {
  color: #7e8ca6;
}
.hilightRow {
  border-top: 2px solid #acb4ac;
}
.columnCellMin {
  padding: 16px 8px !important;
}

@media only screen and (max-width: 600px) {
  .inputLabel {
    font-size: 12px;
    font-weight: 550;
    padding-right: 30px;
    text-align: end;
  }
  .columnCell {
    font-size: 12px !important;
    font-weight: 550 !important;
  }
  .nameColumn {
    font-size: 12px !important;
    font-weight: 550 !important;
  }
}
