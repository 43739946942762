.navBar {
  width: 200px;
  padding-bottom: 90px !important;
}
.navIcon {
  margin-right: 5px;
  margin-left: 5px;
  margin-top: 0px;
  vertical-align: middle;
}
.title {
  font-size: 14px;
  font-weight: 500;
  margin-left: 8px;
}
.username {
  font-size: 11px;
  margin-left: 8px;
  font-weight: bold;
  color: #03204c;
}
.Shop {
  font-size: 12px;
  margin-left: 8px;
  font-weight: bold;
  color: #03204c;
}
.navItem {
  padding: 10px;
  color: #fff;
  cursor: pointer;
}
.navBarIconColor {
  color: #fff;
}
.navItem:link,
.navItem:visited {
  color: white;
  text-align: center;
  display: inline-block;
}
.subMenu {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  padding: 6px;
  padding-left: 40px;
}
.profileSec {
  padding-bottom: 10px;
  padding-left: 10px;
  background: #72c7ec;
  display: flex;
  flex-direction: column;
}
