.titleBarLayout {
  background-color: #03204c;
  width: 100%;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  height: 65px;
}
.appHeading {
  margin-right: 20px;
}
.logoText {
  font-weight: bold;
  font-size: 24px;
  color: #fff;
  margin-top: 0px;
  margin-left: 10px;
}
.logoTextColor {
  font-weight: 800;
  font-size: 24px;
  color: #3f8dce;
  margin-top: 0px;
  margin-left: 10px;
}
.flexChildCenter {
  align-self: center;
}
.logoWrapper {
  display: flex;
  flex: 50%;
}
.profileWrapper {
  justify-content: space-between;
  display: flex;
  flex: 50%;
}
.profileLogo {
  margin-right: 10px;
}
.profileTextWrapper {
  margin-right: 0px;
}
.profileTextMain {
  font-weight: 500;
  font-size: 12px;
  color: #fff;
  margin-right: 25px;
}
.profileTextSub {
  font-size: 11px;
  color: #fff;
}
.shopListPopup {
  color: #72777a;
  font-size: 16px;
  background-color: white;
  position: absolute;
  right: 24px;
  top: 75px;
  z-index: 100;
}
.shopListItem {
  padding: 15px 30px;
}
.titleBarEnd {
  display: flex;
  align-items: center;
}
.notification {
  margin-right: 10px;
  cursor: pointer;
}
.notification svg {
  transform: rotate(18deg);
}
.actionButtons {
  justify-content: center;
  align-self: center;
  margin-right: 10px;
}
.actionBtnIcon {
  margin-right: 6px;
  font-size: 16px !important;
}
.actionBtn {
  font-size: 12px !important;
  font-weight: 500 !important;
}
.username {
  font-size: 10px;
  margin-left: 8px;
  color: white;
}
.Shop {
  font-size: 12px;
  margin-left: 8px;
  color: grey;
}
.profileSec {
  padding: 15px;
  cursor: pointer;
}
.popUp {
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  z-index: 999 !important;
}
.profileNameLayout {
  display: block;
}
.toggleMenuButton {
  display: none;
  margin: 10px;
}
.messageList {
  display: flex;
  padding: 5px 0;
  width: 100%;
  border-bottom: 1px solid #eaeaea;
  border-radius: 5px;
}
.messageList:hover {
  background-color: #f5f5f5;
}
.message {
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column !important;
  padding: 7px 5px;
}
.header {
  border-bottom: 1px solid #dddddd;
  padding: 6px 4px;
}
.header span{
  font-size: 16px;
  font-weight: 600;
}
.messageIndicator {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.messageIndicator:hover {
  border: 0.5px solid #cbcbcb;
  background-color: #eaeaea;
}
.messageIndicator div {
  height: 7px;
  width: 7px;
  background-color: #3f8dce;
  border-radius: 50%;
}
.message p {
  margin: 0 0 5px 0;
  font-size: 14px;
  font-weight: 500;
}
.message span {
  font-size: 13px;
  font-weight: 400;
}
.defaultText {
  font-size: 14px;
  font-style: italic;
  color: #767676;
}
.link {
  cursor: pointer;
  font-size: 14px !important;
  color: #1d57ac;
  font-weight: 600 !important;
}

/* Mobile and Tab */
@media only screen and (max-width: 600px) {
  .logoWrapper {
    display: none;
  }
  .profileNameLayout {
    display: none;
  }
  .toggleMenuButton {
    display: block;
  }
}

@media print {
  .titleBarLayout {
    display: none;
  }
}
