.contentWrapper {
  padding: 10px;
}
.title {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
}
.menuTitle {
  font-size: 15px;
  font-weight: 400;
  padding-left: 8px;
  color: gray;
}
/* changeable section */
.changeable {
  background-color: #fff;
  border-top: 1px solid #d2d6de;
  margin-top: 10px;
  border-radius: 5px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 0px 1px;
}
.subTitle {
  font-size: 18px;
  font-weight: 500;
}
/* filter section */
.filterSec {
  display: flex;
  justify-content: space-between;
}

.filerInputSec {
  display: flex;
}
.selectBox {
  min-width: 200px !important;
}
.selectItem {
  padding-left: 5px;
}
.searchSec {
  padding: 5px;
  margin-left: 20px;
  min-width: 250px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid rgba(0, 0, 1, 0.1);
  border-radius: 5px;
}
.serachSec:focus-within {
  border-color: black;
}
.searchInput {
  border: none;
  width: 80%;
  font-size: 17px;
}
.searchInput::placeholder {
  font-size: 15px;
  color: rgba(0, 0, 1, 0.3);
}
.searchInput:focus-visible {
  outline: none;
}
.searchIcon {
  font-size: 28px;
}
/* action button section */
.actionButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}
.actionBtn {
  font-size: 12px !important;
  font-weight: 400;
  margin-left: 5px !important;
}
.actionBtnIcon {
  margin-right: 6px;
  font-size: 16px !important;
}

.marginTop {
  margin-top: 4px;
}
